import { Text, Pressable, Dimensions } from "react-native";
import getStyles from "./CustomButtonOption.style";
import palette from "../../styles/colors";


const CustomButtonOption = ({
	fullWidth,
	buttonText,
	width,
	disabled,
	selected,
	type,
	onPress,
}) => {
	const screenDimensions = Dimensions.get("screen");
	const styles = getStyles(screenDimensions);

	return (
		<Pressable
			style={[
				styles.button,
				{
					width: width || "auto",
					backgroundColor: "rgba(255, 255, 255, 0.8)",
					alignSelf: fullWidth ? "stretch" : "center",
					// borderRadius: 20,
					alignItems: "center",
				},
			]}
			onPress={onPress}
			disabled={disabled}
		>
			<Text
				style={[
					styles.buttonText,
					{ color: palette["black"] },
				]}
			>
				{buttonText}
			</Text>
		</Pressable>
	);
};

export default CustomButtonOption;
