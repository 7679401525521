import { Text, Pressable, Dimensions } from "react-native";
import getStyles from "./CustomButton.style";
import palette from "../../styles/colors";


const CustomButtonTile = ({
	fullWidth,
	buttonText,
	width,
	height,
	disabled,
	selected,
	type,
	onPress,
}) => {
	const screenDimensions = Dimensions.get("screen");
	const styles = getStyles(screenDimensions);

	return (
		<Pressable
			style={[
				styles.button,
				{
					width: width || "auto",
					height: height || "auto",
					backgroundColor: disabled || selected ? "transparent" : palette[type],
					borderWidth: 2,
					borderColor: palette[type],
				},
			]}
			onPress={onPress}
			disabled={disabled}
		>
			<Text
				style={[
					styles.buttonText,
					{ color: disabled || selected ? palette[type] : "white" },
				]}
			>
				{buttonText}
			</Text>
		</Pressable>
	);
};

export default CustomButtonTile;
