import { StyleSheet } from "react-native";
import palette from "../../styles/colors";

const getStyles = (screenDimensions) => {
	const isTablet = screenDimensions.width > 1000;

	const styles = StyleSheet.create({
		container: {
			flex: 1,
			height: "100%",
			width: "100%",
			justifyContent: "center",
			alignItems: "center",
		},
		backgroundImage: {
			flex: 1,
			alignItems: "center",
			justifyContent: "center",
		},
		innerContainer: {
			width: "100%",
			alignItems: "center",
			justifyContent: "center",
		},
		outerOptionsContainer: {
			width: isTablet ? "70%" : "100%",
			alignItems: "center",
			flexDirection: 'column', // inner items will be added vertically
			flexGrow: 1,
			justifyContent: 'space-between' // will create the gutter between body and footer

		},
		titleText: {
			fontSize: isTablet ? 30 : 24,
			color: palette.white,
			fontWeight: "bold",
			marginVertical: 20,
			marginBottom: 30,
		},
		subtitle: {
			fontSize: isTablet ? 20 : 14,
			fontWeight: "bold",
			marginVertical: 10,
			color: palette.primary,
		},
		optionsContainer: {
				flexDirection: 'row',
				flexWrap: 'wrap',
				justifyContent:'center',
				alignItems:'center',
		},
		homeImage: {
			height: isTablet ? "75%" : 400,
			width: "100%",
			position: "absolute",
			bottom: isTablet ? -80 : -10,
		},
		input: {
			height: 40,
			margin: 12,
			borderWidth: 1,
			padding: 10,
			borderRadius: 20,
			backgroundColor: palette.offWhite,
			color: "black",
		},
		buttonsContainer: {
			justifyContent: "space-between",
			marginBottom: isTablet ? 20 : 40,
			marginLeft: isTablet ? 0 : 0,
			flex: 1,
			width: "100%",
			height: "100%",
			flexDirection: 'row',
			flexWrap: 'wrap',
			alignItems: 'flex-start'
		},
		categorySelected:{
			width:156,
			height:132,
			margin:10,
			borderRadius: 20,
			backgroundColor:'#ff8fa2',
			borderStyle: "solid",
			borderColor: "#efeefc",
			borderWidth: 2,
			justifyContent:'center',
			alignItems:'center'
		},
		category:{
			width:140,
			height:140,
			margin:12,
			backgroundColor:"rgba(0, 0, 0, 0.8)",
			justifyContent:'center',
			alignItems:'center'
		},
		categoryTitleSelected:{
			marginTop:54,
			fontSize:16,
			textAlign:'center',
			color:'#ffffff',
		},
		categoryTitle:{
			fontSize:16,
			textAlign:'center',
			color:palette.white,
		},
		sliderContainer: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
			marginBottom: 5,
		},
		slider: {
			width: "90%",
			height: 40,
		},
		questionType: {
			display: "flex",
			flexDirection: isTablet ? "column" : "row",
		},
		iconLayout: {
			maxHeight: "100%",
			maxWidth: "100%",
			position: "absolute",
			overflow: "hidden",
		},
		icon: {
			width: "30.97%",
			right: "34.19%",
			left: "34.84%",
			bottom: "51.52%",
			top: "20.00%",
			height: "36.36%",
			maxHeight: "100%",
			maxWidth: "100%",
		},
	});
	return styles;
};

export default getStyles;
