import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useState, useRef } from "react";
import {
	Image,
	Text,
	SafeAreaView,
	FlatList,
	View,
	ActivityIndicator,
	Dimensions, TouchableOpacity, ImageBackground
} from "react-native";
import { useQuizContext } from "../../context/QuizContext.jsx";
import ResultItem from "../../Components/ResultItem/ResultItem.jsx";
import CustomButton from "../../Components/CustomButton/CustomButton.jsx";
import GradientWrapper from "../../Components/GradientWrapper/GradientWrapper.jsx";
// import LottieView from "lottie-react-native";
import getStyles from "./Finish.style.";

const Finish = () => {

	const { questionType, quizQuestions, recordedAnswers, reset } = useQuizContext();
	const [finalScore, setFinalScore] = useState(null);

	const navigation = useNavigation();
	const animation = useRef(null);

	const screenDimensions = Dimensions.get("screen");
	const styles = getStyles(screenDimensions);

	const getScore = () => {
		let score = 0;
		for (let i = 0; i < quizQuestions.length; i++) {
			if (quizQuestions[i].correctAnswer === recordedAnswers[i].answer) {
				score++;
			}
		}
		setFinalScore(score);
	};

	const questionTypesTranslationMap = {
		"Sport": "Sport",
		"Entertainment": "Under-\nhållning",
		"Soccer": "Fotboll",
		"Culture": "Kultur",
		"Experiment": "Blandat",
	};

	const playAgain = () => {
		navigation.navigate("Quiz");
		reset("answers");
	};

	const quit = () => {
		navigation.navigate("Starter");
		reset("all");
	};

	useEffect(() => {
		getScore();
	}, []);

	if (finalScore === null) return <ActivityIndicator />;

	return (
			<SafeAreaView style={styles.container} bounces={false} >
				<ImageBackground source= {require('../../assets/danewsquiz/dnq_background.png')} resizeMode="cover" style={styles.backgroundImage}>
					<View style={styles.topbox}>
						<Text style={styles.topboxText}>
							{questionTypesTranslationMap[questionType]?.toUpperCase()}
						</Text>
					</View>
				<View style={styles.innerContainer}>

					<View style={styles.listContainer}>
						<Text style={styles.scoreAnnouncement}>
							Resultat: {finalScore} rätt av {quizQuestions.length}
							{/*You scored {finalScore} out of {quizQuestions.length}*/}
						</Text>

						<FlatList
							style={styles.list}
							data={recordedAnswers}
							renderItem={({ item }) => (
								<ResultItem questions={quizQuestions} item={item} />
							)}
							keyExtractor={(item) => item.id}
						/>
					</View>
				</View>
					<TouchableOpacity
						style={styles.bottombox}
						onPress={() => quit()}>
						<Text style={styles.bottomboxText}>
							NYTT QUIZ!
						</Text>
					</TouchableOpacity>
				</ImageBackground>
			</SafeAreaView>
	);
};

export default Finish;
