import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import {Image, Text, SafeAreaView, ActivityIndicator, View, Dimensions, ImageBackground, StatusBar} from "react-native";
import CustomButtonOption from "../../Components/CustomButtonOption/CustomButtonOption.jsx";
import { useQuizContext } from "../../context/QuizContext.jsx";
import GradientWrapper from "../../Components/GradientWrapper/GradientWrapper.jsx";

import getStyles from "./Quiz.style.js";
import {
	getTrueFalseQuestions,
	getSpecificNumberOfRegularQuestions,
} from "../../api/quiz.js";

const Quiz = () => {
	const {
		quizQuestions,
		numQuestions,
		questionType,
		updateQuestions,
		addAnswer,
		counter,
		updateCounter,
	} = useQuizContext();

	const screenDimensions = Dimensions.get("screen");
	const styles = getStyles(screenDimensions)

	const [selectedOption, setSelectedOption] = useState("");

	const navigation = useNavigation();

	const endOfQuestions = counter === numQuestions - 1;

	const fetchQuestions = async () => {
		if (questionType === "True or False") {
			const trueOrFalseQuestions = await getTrueFalseQuestions(numQuestions);
			await updateQuestions(trueOrFalseQuestions);
		} else {
			const regularQuestions = await getSpecificNumberOfRegularQuestions(
				questionType,
			);
			await updateQuestions(regularQuestions);
		}
	};

	const questionTypesTranslationMap = {
		"Sport": "Sport",
		"Entertainment": "Under-\nhållning",
		"Soccer": "Fotboll",
		"Culture": "Kultur",
		"Experiment": "Blandat",
	};

	const saveAnswer = (answer) => {
		setSelectedOption(answer);

		addAnswer({ id: counter, answer: answer });

		if (!endOfQuestions) {
			goToNextQuestion();
		} else {
			navigation.navigate("Finish");
		}
	};

	const goToNextQuestion = () => {
		const nextQuestion = counter + 1;
		updateCounter(nextQuestion);
		setSelectedOption("");
	};

	useEffect(() => {
		fetchQuestions();
	}, []);

	if (quizQuestions.length === 0) {
		return <ActivityIndicator />;
	} else {
		return (
				<SafeAreaView style={styles.container} bounces={false}>
					<StatusBar hidden />
					<ImageBackground source= {require('../../assets/danewsquiz/dnq_background.png')} resizeMode="cover" style={styles.backgroundImage}>
					<View style={styles.topbox}>
						<Text style={styles.topboxText}>
							{questionTypesTranslationMap[questionType].toUpperCase()}
						</Text>
					</View>

					<View style={styles.innerContainer}>
						<Text style={styles.title}>FRÅGA {counter + 1}/10</Text>
						<Image
							style={styles.tinyLogo}
							source={{
								uri: quizQuestions[counter].image,
							}}
						/>
						<Text style={styles.question}>
							{quizQuestions && quizQuestions[counter].question}
						</Text>
						<View style={styles.buttonContainer}>
							{quizQuestions &&
								quizQuestions[counter].answers.map((answer, index) => (
									<CustomButtonOption
										key={`${answer}-${index}`}
										buttonText={answer}
										onPress={() => saveAnswer(answer)}
										type="primary"
										width="100%"
									/>
								))}
						</View>
					</View>
					</ImageBackground>
				</SafeAreaView>
		);
	}
};

export default Quiz;
