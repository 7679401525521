import {useNavigation} from "@react-navigation/native";
import * as React from "react";
import {Image, ImageBackground, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {useQuizContext} from "../../context/QuizContext";
import {useState} from "react";


const SplashScreen = () => {
  const { quizQuestions, recordedAnswers, reset } = useQuizContext();
  const [finalScore, setFinalScore] = useState(null);

  const navigation = useNavigation();

  return (
      <View style={styles.container}>
        <ImageBackground source= {require('../../assets/danewsquiz/dnq_background.png')} resizeMode="cover" style={styles.backgroundImage}>
          <View style={styles.appLogo}>
            <TouchableOpacity
                onPress={() => {navigation.navigate("Starter")}}
                style={styles.entryButton}
            >
              <Image
                  style={styles.logoIcon}
                  contentFit="cover"
                  source={require("../../assets/danewsquiz/dnq_logo.png")}
              />

            </TouchableOpacity>
          </View>
        </ImageBackground>
      </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  backgroundImage: {
    flex: 1,
    justifyContent: 'center',
  },
  entryButton: {
    width: "100%",
    height: "100%",
    top: -60,
    position: "relative",
    textAlign: "center",
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  oIcon: {
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
  },
  queezy: {
    fontSize: 36,
    letterSpacing: -0.4,
    lineHeight: 50,
    fontWeight: "800",
    color: "#fff",
    textAlign: "center",
    marginTop: 20,
  },
  logoIcon: {
    width: 200,
    height:200,
    textAlign: "center",
    overflow: "hidden",
  },
  appLogo: {
    position: "absolute",
    width: 160,
    height:160,
    top: "50%",
    left: "50%",
    textAlign: "center",
    transform: "translate(-50%, -25%)",
    // backgroundColor: "red",
  },
  splashScreen: {
    backgroundColor: "#6a5ae0",
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
});

export default SplashScreen;
