import { StyleSheet } from "react-native";
import palette from "../../styles/colors";

const getStyles = (screenDimensions) => {
	const isTablet = screenDimensions.width > 1000;

	const styles = StyleSheet.create({
		container: {
			flex: 1,
			height: "100%",
			width: "100%",
			justifyContent: "center",
			alignItems: "center",
		},
		backgroundImage: {
			flex: 1,
			alignItems: "center",
			justifyContent: "center",
			height: "100%",
			width: "100%",
		},
		innerContainer: {
			width: "100%",
			height: "100%",
			alignItems: "center",
			paddingHorizontal: 12,
			backgroundColor: "rgba(0, 0, 0, 0.3)",
		},
		scoreAnnouncement: {
			fontSize: 18,
			textAlign: "center",
			color: palette.offBlack,
			marginVertical: 10,
		},
		topbox: {
			position: "absolute",
			top: 0,
			right: 0,
			width: 80,
			height: 80,
			backgroundColor: palette.redRed,
			justifyContent: 'center',
			alignItems: 'center',
			zIndex: 1000,
			flex: 1,
		},
		topboxText: {
			justifyContent: 'center',
			alignItems: 'center',
			textAlign: "center",
			fontSize: 10,
			color: palette.white,
		},
		bottombox: {
			width: 120,
			height: 120,
			backgroundColor: "rgba(0, 0, 0, 0.8)",
			alignItems: "center",
			zIndex: 1000,
			position: 'absolute', //Here is the trick
			bottom: 0, //Here is the trick
			justifyContent: 'space-between',
			flexDirection: 'row',
		},
		bottomboxText: {
			width: "100%",
			height: "100%",
			textAlign: "center",
			color: palette.white,
			lineHeight: 120,
			fontSize: 16,
		},
		listContainer: {
			width: "90%",
			height: screenDimensions.height - 60,
			padding: 20,
			paddingTop: 60,
			top: 30,
			marginBottom: 0,
			backgroundColor: palette.white,
		},
		list : {
			marginTop: 10,
			paddingBottom: 80,
			backgroundColor: palette.white,
			// height: screenDimensions.height-60,
			// flexGrow: 0
		},
		reviewAnswer: {
			fontSize: isTablet ? 20 : 14,
			marginVertical: 5,
		},
	});
	return styles;
};

export default getStyles;
