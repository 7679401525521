import { useNavigation } from "@react-navigation/native";
import React from "react";
import {Text, SafeAreaView, View, Image, Dimensions, TouchableOpacity, ImageBackground} from "react-native";
import CustomButton from "../../Components/CustomButton/CustomButton.jsx";
import quizCat from "../../assets/images/quizcat.png";
import { LinearGradient } from "expo-linear-gradient";
import palette from "../../styles/colors.js";
import { useQuizContext } from "../../context/QuizContext.jsx";
import Slider from "@react-native-community/slider";
import Pawprint from "../../assets/images/pawprint.png";
import getStyles from "./Starter.style.js";
import GradientWrapper from "../../Components/GradientWrapper/GradientWrapper";
import CustomButtonTile from "../../Components/CustomButtonTile/CustomButtonTile";

const questionTypes = [ "Sport", "Entertainment", "Soccer", "Culture",  "Experiment"];
const questionTypesTranslationMap = {
	"Sport": "Sport",
	"Entertainment": "Under-\nhållning",
	"Soccer": "Fotboll",
	"Culture": "Kultur",
	"Experiment": "Blandat",
};

const Starter = () => {
	const { numQuestions, questionType, updateQuestionType } =
		useQuizContext();

	const screenDimensions = Dimensions.get("screen");
	const styles = getStyles(screenDimensions);

	const navigation = useNavigation();

	const canStart = questionType;

	return (
			<SafeAreaView style={styles.container} bounces={false}>
				<ImageBackground source= {require('../../assets/danewsquiz/dnq_background.png')} resizeMode="cover" style={styles.backgroundImage}>

				{/*<View style={styles.questionType}>*/}
				{/*	<Text style={styles.titleText}>*/}
				{/*		Choose Category*/}
				{/*	</Text>*/}
				{/*</View>*/}
				<View style={styles.innerContainer}>

					<View style={styles.outerOptionsContainer}>
						<View style={styles.optionsContainer}>
								{questionTypes.map((qType, index) => (

									<TouchableOpacity
										key={`${qType}-${index}`}
										style={questionType != qType ? styles.category : styles.category}
										onPress={() => {updateQuestionType(qType); navigation.navigate("Quiz")}}
										selected={questionType != qType}
									>
										<Text
											style={questionType != qType ? styles.categoryTitle : styles.categoryTitle}
										>{questionTypesTranslationMap[qType].toUpperCase()}</Text>
									</TouchableOpacity>
								))}

						</View>

						</View>
				</View>
				{/*<Image*/}
				{/*	source={quizCat}*/}
				{/*	alt="a hero cat on a quest"*/}
				{/*	style={styles.homeImage}*/}
				{/*/>*/}
				</ImageBackground>
			</SafeAreaView>
	);
};


export default Starter;
