import { StyleSheet } from "react-native";
import palette from "../../styles/colors";

const getStyles = (screenDimensions) => {
	const isTablet = screenDimensions.width > 1000;
	const isSmall = screenDimensions.height < 668;

	const styles = StyleSheet.create({
		container: {
			flex: 1,
			height: "100%",
			width: "100%",
			justifyContent: "center",
			alignItems: "center",
		},
		backgroundImage: {
			flex: 1,
			alignItems: "center",
			height: "100%",
			width: "100%",
		},
		innerContainer: {
			top: isSmall ? 90 : 140,
			width: "100%",
			alignItems: "center",
			paddingHorizontal: 28,
		},
		title: {
			alignSelf: "flex-end",
			marginBottom: 4
		},
		tinyLogo: {
			width: "100%",
			height: 200,
			resizeMode: 'contain',
		},
		topbox: {
			position: "absolute",
			top: 0,
			right: 0,
			width: 80,
			height: 80,
			backgroundColor: palette.redRed,
			justifyContent: 'center',
			alignItems: 'center',
			zIndex: 1000,
			flex: 1,
		},
		topboxText: {
			justifyContent: 'center',
			alignItems: 'center',
			textAlign: "center",
			fontSize: 10,
			color: palette.white,
		},
		question: {
			fontSize: isSmall ? 16 : 20,
			// lineHeight: 28,
			width: "100%",
			backgroundColor: palette.white,
			height: isSmall ? 48 : 80,
			textAlign: "left",
			alignSelf: "flex-start",
			fontWeight: "500",
			marginTop: 12,
			marginBottom: 18,
		},
		buttonContainer: {
			justifyContent: "center",
			alignItems: "center",
			width: "100%",
			marginBottom: 10,
		},
	});
	return styles
}

export default getStyles;
