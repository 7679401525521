import { StyleSheet } from "react-native";
import palette from "../../styles/colors";

const getStyles = (screenDimensions) => {
	const isTablet = screenDimensions.width > 1000;

	const styles = StyleSheet.create({
		button: {
			justifyContent: "center",
			alignItems: "center",
			// borderRadius: 20,
			paddingVertical: 16,
			margin: 8,
		},
		buttonText: {
			fontWeight: "bold",
			textTransform: "uppercase",
			fontSize: isTablet ? 20 : 14,
		},
	});
	return styles;
};

export default getStyles;
