import { StyleSheet } from "react-native";
import palette from "../../styles/colors";

const getStyles = (screenDimensions) => {
	const isTablet = screenDimensions.width > 1000;
	const isSmall = screenDimensions.height < 668;

	const styles = StyleSheet.create({
		button: {
			justifyContent: "left",
			alignItems: "left",
			// borderRadius: 20,
			paddingVertical: 10,
			paddingHorizontal: 10,
			margin: 8,
			height: isSmall ? 70 : 80,
			borderStyle: "solid",
			borderWidth: 1,
			borderColor: "#999999",

		},
		buttonText: {
			fontSize: isSmall ? 14 : 16,
			alignSelf: "flex-start",
		},
	});
	return styles;
};

export default getStyles;
