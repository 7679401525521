import React from "react";
import Navigation from "./src/routes/RouteContainer";
import { QuizProvider } from "./src/context/QuizContext";
import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';

const App = () => {
  return (
      <QuizProvider>
        <Navigation />
      </QuizProvider>
  );
}

export default App;


