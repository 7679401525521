const palette = {
    primary: "#06283D",
    accent: "#256D85",
    secondary: "#47B5FF",
    background: "#6a5ae0",
    offBlack: "#171717",
    offWhite: "#fcfcfc",
    grey: "#b0b0b0",
    darkGrey: "#6e6e6e",
    white: "#ffffff",
    buttonActive: "#6a5ae0",
    buttonInactive: "#b4ade2",
    buttonDisabled: "#ff8fa2",
    redRed : "#DC0D15",
    greenGreen : "#009640",
};

export default palette;

