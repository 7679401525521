import { createStackNavigator } from "@react-navigation/stack";
import Finish from "../screens/Finish/Finish";
import Quiz from "../screens/Quiz/Quiz";
import Starter from "../screens/Starter/Starter";
import palette from "../styles/colors";
import SplashScreen from "../screens/Splash/Splash";

const Stack = createStackNavigator();

const AppContainer = () => {
	return (
		<Stack.Navigator
			screenOptions={{
				headerShown: false
			}}		>
			{/*<Stack.Screen*/}
			{/*	name="Finish"*/}
			{/*	component={Finish}*/}
			{/*	options={{*/}
			{/*		headerShown: false,*/}
			{/*	}}*/}
			{/*/>*/}
			<Stack.Screen
				name="SplashScreen"
				component={SplashScreen}
				options={{ headerShown: false }}
			/>
			<Stack.Screen
				name="Starter"
				component={Starter}
				options={{ headerShown: false }}
				// options={{
				// 	headerStyle: {
				// 		backgroundColor: palette.background,
				// 		borderBottomWidth: 0,
				// 		fontSize: 36,
				// 	},
				// 	headerTitleStyle: {
				// 		fontSize: 30,
				// 	},
				// 	headerTintColor: palette.white,
				// }}
			/>

			<Stack.Screen
				name="Quiz"
				component={Quiz}
				options={{ headerShown: false }}
			/>
			<Stack.Screen
				name="Finish"
				component={Finish}
				options={{ headerShown: false }}

			/>
		</Stack.Navigator>
	);
};

export default AppContainer;
