import { Text, View, Dimensions } from "react-native";
import getStyles from "./ResultItem.styles";
import palette from "../../styles/colors";
import { Entypo } from "@expo/vector-icons";

const ResultItem = ({ questions, item }) => {
	const screenDimensions = Dimensions.get("screen");
	const styles = getStyles(screenDimensions);

	return (
		<View style={styles.container}>
			<View style={styles.textArea}>
				{/*<Text style={styles.text}>*/}
				{/*	<Text style={styles.bold}>No.</Text> {item.id + 1}*/}
				{/*</Text>*/}
				<Text style={[styles.text, styles.bold]}>
					{item.id + 1}. {questions[item.id].question}
				</Text>
				<Text style={styles.textAnswer}>
					 Du svarade: {item.answer}
				</Text>
				{questions[item.id].correctAnswer !== item.answer ? (
					<Text style={styles.textAnswer}>
						Rätt svar: {questions[item.id].correctAnswer}
					</Text>
				) : ''}

			</View>
			<View style={styles.icon}>
				{questions[item.id].correctAnswer === item.answer ? (
					<Entypo name="controller-record" size={40} color={palette.greenGreen} />
				) : (
					<Entypo name="controller-record" size={40} color={palette.redRed} />
				)}
			</View>
		</View>
	);
};

export default ResultItem;
